import { Alert, CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../components";
import { useDataContext } from "../../context/DataContext";
import http from "../../utils/http";
import CourseDetails from "./CourseDetails";
import DependentDetails from "./DependerntDetails";
import Enclosures from "./Enclosures";
import MemeberDetails from "./MemberDetails";
import { IFormState } from "./types";
import { Store } from "pullstate";

export const formState = new Store<IFormState>({
  course: {
    id: "",
    name: "",
    courseId: "",
  },
  workerName: "",
  workerMobile: "",
  workerAadharNumber: "",
  workerRationCardNumber: "",
  bocRegNo: "",
  reqcode: "",
  name: "",
  gender: "",
  fatherName: "",
  motherName: "",
  dateOfBirth: "",
  renewedUpto: "",
  caste: "",
  mobile: "",
  aadharNumber: "",
  aadharFile: "",
  education: {
    name: "",
    id: "",
    courses: [],
  },
  applicantPhoto: "",
  houseNumber: "",
  village: "",
  sign: "",
  district: {
    id: "",
    name: "",
    code: "",
  },

  state: "",
  pincode: "",
  batchNumber: "",
  year: "",
});

export const formFieldData = new Store<{
  districts: any[];
  courses: any[];
  education: any[];
}>({
  districts: [],
  courses: [],
  education: [],
});

const FormPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [alertMessage, setAlertMessage] = useState("");
  const { bocwMember } = useDataContext();

  const state = formState.useState((s) => s);
  const formData = formFieldData.useState((s) => s);

  useEffect(() => {
    if (!bocwMember) {
      window.location.href = "/";
    }
    formState.update((s) => {
      s.workerName = bocwMember?.worker_name;
      s.name = bocwMember?.traine_name;
    });
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const [districtRes, courseRes, education] = await Promise.all([
        http.get(`/district`),
        http.get(`/course`),
        http.get(`/education`),
      ]);

      formFieldData.update((s) => {
        s.districts = districtRes.data;
        s.education = education.data;
        s.courses = courseRes.data
          .sort((a, b) => a?.id - b?.id)
          .map((item) => ({
            ...item,
            name: `Course ${item?.courseId} - ${item?.name}`,
          }));
      });

      formState.update((s) => {
        s.batchNumber = `B${
          districtRes?.data?.find(
            (item: any) => item.id == bocwMember?.distcode
          )?.id
        }_${format(new Date(), "MMM")}${format(new Date(), "yy")}`;
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleChange = (name: string, value: any) => {
    formState.update((s) => {
      s[name] = value;
    });
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (!mobileRegex.test(state.mobile) || state?.mobile.includes("-")) {
      setAlertMessage("Enter Valid Mobile Number");
      return;
    }

    if (state?.aadharNumber.length < 12 || state?.aadharNumber.includes("-")) {
      setAlertMessage("Enter valid Aadhar number");
      return;
    }
    if (!state.aadharFile) {
      setAlertMessage("Aadhar Card upload is required");
      return;
    }
    if (!state.sign) {
      setAlertMessage("Signature upload is required");
      return;
    }
    if (!state.applicantPhoto) {
      setAlertMessage("Passport photo upload is required");
      return;
    }

    history.push("/preview");
  };

  if (loading) return <CircularProgress />;

  return (
    <div className="mt-8 mx-5 pb-10">
      <div className="text-center text-base md:text-xl font-semibold  px-2 md:px-0 mb-6">
        <h1 className="text-xl md:text-2xl mb-4">
          Telangana Building And Other Construction Workers Welfare Board
        </h1>
        <div className="space-y-2">
          <h2>SETWIN Application form for Online Skill Upgradation</h2>
          <h2>
            Training Programme for Registered BOC Workers and their Dependents
          </h2>
        </div>
      </div>
      <form onSubmit={handleNext}>
        <CourseDetails
          state={state}
          handleChange={handleChange}
          courses={formData?.courses}
        />
        {state?.course?.id && (
          <>
            <MemeberDetails
              state={state}
              handleChange={handleChange}
              memberDetails={formData}
            />
            <DependentDetails
              state={state}
              handleChange={handleChange}
              dependentDetails={formData}
            />
            {/* <IdetificationDetails
							state={state}
							handleChange={handleChange}
							districts={data.districts}
						/> */}
            <Enclosures />
            <div className="my-3">
              {alertMessage && <Alert severity="error">{alertMessage}</Alert>}
            </div>
            <div className="flex justify-center items-center">
              <Button
                label="Preview"
                type="submit"
                className="w-1/2 mx-auto mt-8"
              />
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default FormPage;
