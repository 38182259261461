import { Autocomplete } from "@mui/material";
import { formFieldData } from ".";
import { TextField } from "../../components";
import FormControl from "../../components/FormControl";
import { IFormProps } from "./types";

interface IProps extends IFormProps {
  courses: any[];
}

const CourseDetails = ({ state, handleChange, courses }: IProps) => {
  const formFieldState = formFieldData.useState((s) => s);
  console.log(state, formFieldState.courses, "satee");

  console.log(
    formFieldState?.education
      ?.find((item) => item?.id === state?.education?.id)
      ?.courses?.map((item) => ({
        ...item,
        name: `Course ${item?.courseId} - ${item?.name}`,
      })) || []
  );

  return (
    <div>
      <h2 className="font-semibold">Course Details</h2>
      <FormControl required label="Education Qualification:">
        <div className="md:flex gap-4 space-y-3 md:space-y-0">
          <Autocomplete
            options={formFieldState.education || []}
            value={state.education || ""}
            fullWidth
            onChange={(e: any, newValue: any) =>
              handleChange("education", newValue)
            }
            getOptionLabel={(option: any) => `${option?.name}` || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Education Qualification"
                required
              />
            )}
          />
        </div>
      </FormControl>
      <FormControl required label="Select Course">
        <Autocomplete
          fullWidth
          value={state.course}
          options={
            formFieldState?.education
              ?.find((item) => item?.id === state?.education?.id)
              ?.courses?.map((item) => ({
                ...item,
                name: `Course ${item?.courseId} - ${item?.name}`,
              })) || []
          }
          onChange={(e: any, newValue: any) => handleChange("course", newValue)}
          getOptionLabel={(option: any) =>
            option.name ? ` ${option?.name}` : "" || ""
          }
          renderInput={(params) => (
            <TextField {...params} label="Select Course" required />
          )}
        />
      </FormControl>
      <FormControl label="Select Batch number">
        <TextField
          fullWidth
          disabled
          value={state.batchNumber}
          placeholder="Course Batch Number"
        />
      </FormControl>
    </div>
  );
};

export default CourseDetails;
