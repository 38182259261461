import {KeyboardBackspace} from '@mui/icons-material'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import Layout from '.'
import DataProvider from '../context/DataContext'
import routes, {IRoute} from './page_routes'

const Routes = () => {
	return (
		<DataProvider>
			<Layout>
				<Switch>
					<Redirect exact from='/' to='/login' />
					{routes.map((item: IRoute) => (
						<Route
							key={item.path}
							path={item.path}
							component={item.Component}
							exact
						/>
					))}
					<Route path='*' component={PageNotFound} />
				</Switch>
			</Layout>
		</DataProvider>
	)
}
const PageNotFound = () => {
	return (
		<div className='pt-10'>
			<h1 className='text-lg '>Page Not Found</h1>
			<div className='mt-5'>
				<Link className='hover:underline text-primary' to='/login'>
					<div className='flex items-center gap-3'>
						<KeyboardBackspace />
						<p>Go Back</p>
					</div>
				</Link>
			</div>
		</div>
	)
}
export default Routes
