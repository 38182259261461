import {MenuItem} from '@mui/material'
import {TextField} from '../../components'
import FormControl from '../../components/FormControl'
import {useDataContext} from '../../context/DataContext'
import {IFormProps} from './types'

interface Props extends IFormProps {
	memberDetails: any
}

const MemeberDetails = ({state, handleChange, memberDetails: data}: Props) => {
	const {bocwMember} = useDataContext()
	return (
		<>
			<h2 className='font-semibold pt-4'>Member Details</h2>
			<FormControl label='Name of the BOCWWB Worker:'>
				<TextField disabled value={state?.workerName} />
			</FormControl>

			{/* <FormControl label='Gender:'>
				<TextField
					select
					fullWidth
					value={state.gender}
					onChange={(e) => handleChange('gender', e.target.value)}
					placeholder='Gender'
				>
					<MenuItem value='male'>Male</MenuItem>
					<MenuItem value='female'>Female</MenuItem>
					<MenuItem value='other'>Other</MenuItem>
				</TextField>
			</FormControl> */}
			{/* <FormControl label='Father/Husband name: '>
				<TextField
					required
					value={state.fatherName}
					onChange={(e) => handleChange('fatherName', e.target.value)}
					placeholder='Father/Husband name: '
				/>
			</FormControl>
			<FormControl label='Date of birth:'>
				<TextField
					type='date'
					required
					value={state.dateOfBirth}
					onChange={(e) => handleChange('dateOfBirth', e.target.value)}
					placeholder='Date of birth'
				/>
			</FormControl> */}
			<FormControl label='BOCWWB Registration number:'>
				<TextField disabled value={bocwMember?.regno} />
			</FormControl>
			{/* <FormControl label='Renewed upto:'>
				<TextField
					type='date'
					required
					value={state.renewedUpto}
					onChange={(e) => handleChange('renewedUpto', e.target.value)}
					placeholder='Renewed upto'
				/>
			</FormControl>

			<FormControl label='Caste:'>
				<TextField
					select
					fullWidth
					value={state.caste}
					onChange={(e) => handleChange('caste', e.target.value)}
					placeholder='Select Caste'
				>
					<MenuItem value='sc'>SC</MenuItem>
					<MenuItem value='st'>ST</MenuItem>
					<MenuItem value='bc'>BC</MenuItem>
					<MenuItem value='other'>Other</MenuItem>
				</TextField>
			</FormControl> */}
		</>
	)
}
export default MemeberDetails
