import { createTheme, ThemeOptions } from '@mui/material'

const FONT_FAMILY = ['Poppins', 'sans-serif'].join(',')

const themeOptions: ThemeOptions = {
	palette: {
		primary: {
			main: '#1E4B8F',
		},
		secondary: {
			main: '#f50057',
		},
	},
	typography: {
		fontFamily: ['Poppins', 'sans-serif'].join(','),
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
					fontWeight: 'bold',
					fontFamily: FONT_FAMILY,
					fontSize: '1rem',
				},
			},
		},
	},
}

const theme = createTheme(themeOptions)
export default theme
