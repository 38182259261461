import axios from "axios";

export const baseUrl = process.env.REACT_APP_API_URL;

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default http;
