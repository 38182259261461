import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import http from "../utils/http";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDataContext } from "../context/DataContext";
import { Box } from "@mui/system";

const Login = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [type, setType] = useState("d");
  const [submitloding, setSubmitLoading] = useState(false);
  const [state, setState] = useState({
    regno: "",
    traine_name: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const { setBocwMember } = useDataContext();
  useEffect(() => {
    if (state.regno !== "" && state.traine_name !== "" && type !== "") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [state, type]);

  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
    // if (state.regno !== "" && state.traine_name !== "") {
    //   type !== "" && setLoading(true);
    // }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data = {
      regno: state.regno,
      traine_name: state.traine_name,
      traineeis: type === "w" ? "W" : "D",
    };
    // data[`${type}`] = state.traine_name;
    setSubmitLoading(true);

    try {
      const res = await http.post(`/bocw-user`, {
        ...data,
        reqcode:
          "01e9cb964d6ccc686683f7cbc63f5686190b4640e5cef9884d16cf2dc226628d",
      });

      if (res.data?.statusCode != "000") {
        setError(res.data.statusMsg);
        setSubmitLoading(false);
        return;
      }

      setSubmitLoading(false);
      setBocwMember(res?.data);
      history.push("/form");
    } catch (err: any) {
      setSubmitLoading(false);
      setError(err.response.data.message);
    }
  };

  const handleChangeSelect = (event: any) => {
    setType(event.target.value as string);
    if (state.regno !== "" && state.traine_name !== "") {
      setLoading(true);
    }
  };
  const handleAdminLogin = () => {
    history.push("/admin/login");
  };

  return (
    <div className="flex  items-center h-full max-w-screen-md mx-auto text-center">
      <form className="m-5 md:m-12 gap-4 w-full" onSubmit={handleSubmit}>
        <div className="text-center text-base md:text-xl font-semibold mt-3 px-2 md:px-0 mb-6">
          <h1 className="text-xl md:text-2xl mb-4">
            Telangana Building And Other Construction Workers Welfare Board
          </h1>
          <div className="space-y-2">
            <h2>SETWIN Application form for Online Skill Upgradation</h2>
            <h2>
              Training Programme for Registered BOC Workers and their Dependents
            </h2>
          </div>
        </div>
        <h1 className="text-lg md:text-xl text-primary font-semibold">
          Setwin TSBOCWWB Training Registration
        </h1>
        <div className="my-4">
          <TextField
            size="medium"
            variant="outlined"
            autoComplete="off"
            fullWidth
            required
            label="Enter Registration Number"
            value={state.regno}
            onChange={(e) => handleChange("regno", e.target.value)}
          />
        </div>
        <div className="flex my-4">
          <div style={{ minWidth: "200px" }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="grouped-native-select">Select *</InputLabel>
              <Select
                labelId="grouped-native-select"
                id="demo-simple-select"
                value={type}
                label="Select *"
                onChange={handleChangeSelect}
              >
                <MenuItem value={"w"}>Worker</MenuItem>
                <MenuItem value={"d"}>Dependent</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className=" mx-2" style={{ minWidth: "69%" }}>
            <TextField
              size="medium"
              variant="outlined"
              autoComplete="off"
              disabled={type ? false : true}
              fullWidth
              required
              label="Enter name"
              value={state.traine_name}
              onChange={(e) => handleChange("traine_name", e.target.value)}
            />
          </div>
        </div>

        <div className="">
          {error && (
            <Alert severity="error" className="mb-5 capitalize">
              {error}
            </Alert>
          )}
        </div>

        <Button
          disabled={!loading}
          variant="contained"
          size="large"
          className="mt-4 px-9"
          type="submit"
          endIcon={
            submitloding ? (
              <CircularProgress className="text-white" size={20} />
            ) : null
          }
        >
          Submit
        </Button>
      </form>
      <div className="fixed top-28 right-6">
        {/* <Button variant='contained' color='primary' onClick={handleAdminLogin}>
					Admin Login
				</Button> */}
      </div>
    </div>
  );
};

export default Login;
