import {TextField} from '@mui/material'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Button} from '../components'
import {useDataContext} from '../context/DataContext'
import http from '../utils/http'

const Register = () => {
	const [otp, setOtp] = useState<string>('')
	const history = useHistory()

	const {loginMobile} = useDataContext()
	useEffect(() => {
		if (!loginMobile) {
			toast.warn('Mobile Number not found, Login Again')
			history.push('/login')
		}
	}, [])
	const handleSubmit = (e: any) => {
		e.preventDefault()
		try {
			const res = http.post(`/otp/login-bocw-member`, {
				mobile: loginMobile,
				generatedOtp: otp,
			})
		} catch (err) {
			toast.error('Error Logging In')
		}
	}

	return (
		<div className='flex  items-center h-full'>
			<form className='m-12 gap-4 w-full' onSubmit={handleSubmit}>
				<label className='font-bold'>Enter Otp : </label>
				<div className='my-4'>
					<TextField
						size='medium'
						variant='outlined'
						required
						fullWidth
						label='Enter the OTP sent to your mobile number'
						value={otp}
						onChange={({target}) => setOtp(target.value)}
					/>
				</div>
				<Button label='Submit' size='large' className='mt-4' type='submit' />
			</form>
		</div>
	)
}

export default Register
