import {Upload} from '../../components'
import FormControl from '../../components/FormControl'
import {formState} from '.'
import {Store} from 'pullstate'

export const enclosures = new Store<{
	aadharFile: any
	applicantPhoto: any
	sign: any
}>({
	aadharFile: null,
	applicantPhoto: null,
	sign: null,
})

const Enclosures = () => {
	const state = enclosures.useState((s) => s)

	function addImage(v: any, e: any) {
		formState.update((s) => {
			s[e.target.name] = v.key
		})
		enclosures.update((s) => {
			s[e.target.name] = v
		})
	}

	return (
		<div className=''>
			<h2 className='font-semibold pt-3'>Enclosures</h2>
			<FormControl required label='Applicant Aadhar File:'>
				<Upload
					name='aadharFile'
					onChange={addImage}
					data={state?.aadharFile}
				/>
			</FormControl>
			<FormControl required label='Passport size photo'>
				<Upload
					name='applicantPhoto'
					onChange={addImage}
					data={state?.applicantPhoto}
				/>
			</FormControl>
			<FormControl required label='Signature'>
				<Upload name='sign' onChange={addImage} data={state?.sign} />
			</FormControl>
		</div>
	)
}

export default Enclosures
