import {Alert, Button, CircularProgress, TextField} from '@mui/material'
import {Store} from 'pullstate'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import http from '../../utils/http'

export const adminStore = new Store({
	adminToken: '',
})

export default function AdminLogin() {
	const history = useHistory()
	const [state, setState] = useState({
		mobile: '',
		password: '',
	})

	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)

	const handleSubmit = (e: any) => {
		e.preventDefault()
		login()
	}

	const login = async () => {
		setLoading(true)
		try {
			const res = await http.post(`/auth/login`, {
				mobile: state.mobile,
				password: state.password,
			})

			adminStore.update((s) => {
				s.adminToken = res.data.access_token
			})

			history.push('/admin/validate-members')

			setLoading(false)
		} catch (err: any) {
			err && setError(err.response.data.message)
			setLoading(false)
		}
	}

	const handleChange = (e: any) => {
		setState((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}))
	}

	return (
		<div className='mx-4'>
			<div className='w-1/2 flex'>
				<form className='m-12 gap-4 w-full' onSubmit={handleSubmit}>
					<h1 className='font-semibold text-xl text-primary'>Admin Login</h1>
					{error && (
						<Alert severity='error' className='mb-5 capitalize'>
							{error}
						</Alert>
					)}
					<div className='my-4'>
						<label htmlFor='mobile' className='font-semibold mb-4'>
							Mobile
						</label>
						<TextField
							className='mt-2'
							required
							name='mobile'
							size='medium'
							variant='outlined'
							fullWidth
							label='Enter Mobile'
							value={state.mobile}
							onChange={handleChange}
						/>
					</div>
					<div className='my-4'>
						<label htmlFor='loginId' className='font-semibold'>
							Password
						</label>
						<TextField
							required
							className='mt-2'
							name='password'
							size='medium'
							variant='outlined'
							fullWidth
							label='Enter the Password'
							value={state.password}
							onChange={handleChange}
						/>
					</div>

					<Button
						endIcon={
							loading ? (
								<CircularProgress sx={{color: 'white'}} size={24} />
							) : null
						}
						size='large'
						className='mt-4 px-16'
						type='submit'
						variant='contained'
					>
						Login
					</Button>
				</form>
			</div>
		</div>
	)
}
