import { AppBar, Toolbar } from "@mui/material";
import { FC } from "react";

const Layout: FC = ({ children }) => {
  return (
    <main className="h-screen w-full font-poppins">
      <div className="">
        <AppBar position="static">
          <Toolbar>
            <div className="flex-grow ml-8">
              <div className="flex gap-4">
                <img
                  src="/images/telangana.png"
                  className="h-full w-auto rounded-full object-contain"
                  alt="Telangana"
                />
                <img
                  src="/images/setwin.png"
                  className="h-full w-auto rounded-full object-contain"
                  alt="Setwin"
                />
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <div className="max-w-screen-lg mx-auto">{children}</div>
    </main>
  );
};

export default Layout;
