import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {useRef} from 'react'
import {toast} from 'react-toastify'

const CopyToClipboard = ({id}: {id: string | null}) => {
	const ref: any = useRef()

	const handleCopy = () => {
		navigator.clipboard.writeText(ref.current?.innerText)
		toast.success('Copied to Clipboard!')
	}
	return (
		<div
			onClick={handleCopy}
			className='bg-pink border border-gray-400 px-4 py-2 inline-flex gap-3 items-center border-opacity-20 rounded-lg justify-start cursor-pointer'
		>
			<p className='font-bold flex-shrink-0'>Batch number: </p>
			<p ref={ref} className='text-primary text-lg font-semibold'>
				{id}
			</p>
			<ContentCopyIcon fontSize='small' />
		</div>
	)
}

export default CopyToClipboard
