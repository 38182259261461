import {Alert, Button, CircularProgress} from '@mui/material'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {baseUrl} from '../../utils/http'
import {adminStore} from './AdminLogin'

export default function ValidateUsers() {
	const adminState = adminStore.useState((s) => s)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [members, setMembers] = useState<any>(null)

	useEffect(() => {
		if (!adminState.adminToken) {
			window.location.href = '/admin/login'
		}
		getData()
	}, [])

	const getData = async () => {
		const res = await fetchUploadedMembers(adminState?.adminToken)
		setMembers(res)
	}

	const handleClick = () => {
		if (!members?.results?.length) return null
		runCRONJobOnMembers()
	}

	const runCRONJobOnMembers = async () => {
		setLoading(true)
		for (let i = 0, len = members?.results?.length; i < len; i++) {
			await getBOCWDetails({
				// token: adminState?.adminToken,
				id: members?.results[i]?.id,
			})
			console.log('waiting for 5 seconds')
			await timeout()
		}
		setLoading(false)
	}

	return (
		<div className='mx-4'>
			<h2 className='mb-4 text-primary font-semibold text-lg mt-6'>
				Run CRON Job
			</h2>
			<Button
				variant='contained'
				endIcon={loading ? <CircularProgress size={24} /> : null}
				disabled={loading}
				onClick={handleClick}
				className='px-8'
			>
				Start
			</Button>
			<div className='my-4'>
				{error && (
					<Alert severity='error'>{error ?? 'Error Processing Request'}</Alert>
				)}
			</div>
			<pre>{JSON.stringify(members, null, 2)}</pre>
		</div>
	)
}

const fetchUploadedMembers = async (token: any) => {
	try {
		const res = await axios.get(`${baseUrl}/bocw-user?status=uploaded`, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
		})

		return res.data
	} catch (error: any) {
		toast.error(error?.response?.data?.message ?? 'Error Processing Request')
		return null
	}
}

const fetchMemberDetailsAndSave = async (body: any) => {
	try {
		const res = await axios.post(
			`${baseUrl}/bocw-user/${body?.id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${body.token}`,
					'Content-Type': 'application/json',
				},
			}
		)

		return res.data
	} catch (error: any) {
		toast.error(error?.response?.data?.message ?? 'error processing request')
		return null
	}
}

const runCronJob = async (token) => {
	try {
		const res = await axios.post(
			`${baseUrl}/bocw-user/details`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			}
		)
	} catch (error: any) {
		toast.error(error?.response?.data?.message ?? 'error processing request')
		return null
	}
}

const timeout = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve('Success')
		}, 5000)
	})
}

const getBOCWDetails = async ({id}) => {
	try {
		const res = await axios.post(`${baseUrl}/bocw-user`, {
			id,
		})
		return res.status
	} catch (error: any) {
		toast.error(error?.response?.data?.message ?? 'error processing request')
		return null
	}
}
