import {CssBaseline} from '@mui/material'
import {BrowserRouter as Router} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DataProvider from './context/DataContext'
import Layout from './layout'
import Routes from './layout/Routes'

function App() {
	return (
		<Router>
			<CssBaseline />
			<ToastContainer
				theme='colored'
				position='top-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				pauseOnHover
			/>
			<DataProvider>
				<Routes />
			</DataProvider>
		</Router>
	)
}

export default App
