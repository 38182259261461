import { Button } from '@mui/material'

const MyButton = ({ size = 'large', label = '', ...props }) => {
	const isLarge = size === 'large'

	const options = {
		padding: isLarge ? '1rem 3rem' : '0.5rem 1.5rem',
	}
	return (
		<Button
			color='primary'
			variant='contained'
			size='large'
			sx={options}
			{...props}
		>
			{label}
		</Button>
	)
}

export default MyButton
