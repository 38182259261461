import { TextField } from "../../components";
import FormControl from "../../components/FormControl";
import { useDataContext } from "../../context/DataContext";
import { IFormProps } from "./types";

interface Props extends IFormProps {
  dependentDetails: any;
}

const DependentDetails = ({
  state,
  handleChange,
  dependentDetails: data,
}: Props) => {
  const { bocwMember } = useDataContext();

  const isMobileError = (value: any) => {
    var phoneno = /^[0-9]{10}$/;
    return !value.match(phoneno);
  };

  const isAadharValid = (value: any) => {
    var phoneno = /^[0-9]{12}$/;
    return value.match(phoneno);
  };

  const motherNameValidation = (value: any) => {
    var regex = /^[a-zA-Z ]*$/;
    return value.match(regex);
  };

  return (
    <div className="">
      <h2 className="font-semibold pt-3">Dependent Details</h2>

      <FormControl required label="Dependent Name">
        <TextField
          fullWidth
          disabled
          placeholder="Dependent Name"
          value={state.name}
        />
        {/* {bocwMember?.dependents?.map((item) => (
						<MenuItem value={item.dependent_name}>
							{item.dependent_name}
						</MenuItem>
					))} */}
      </FormControl>

      <FormControl required label="Mother name">
        <TextField
          required
          error={!motherNameValidation(state.motherName)}
          helperText={
            motherNameValidation(state.motherName)
              ? ""
              : "No Special Charaters Allowed"
          }
          value={state.motherName}
          onChange={(e) => handleChange("motherName", e.target.value)}
          placeholder="Mother Name"
          autoComplete="off"
        />
      </FormControl>

      <FormControl required label="Mobile number of dependent">
        <TextField
          required
          error={isMobileError(state.mobile)}
          helperText={
            isMobileError(state.mobile) ? "Invalid Mobile Number" : ""
          }
          value={state.mobile}
          onChange={(e) => handleChange("mobile", e.target.value)}
          placeholder="Enter 10 digit mobile number"
          autoComplete="off"
        />
      </FormControl>
      <FormControl required label="Aadhar number of dependent">
        <TextField
          required
          helperText={
            isAadharValid(state.aadharNumber) ? "" : "Invalid Aadhar Number"
          }
          error={!isAadharValid(state.aadharNumber)}
          value={state.aadharNumber}
          onChange={(e) => handleChange("aadharNumber", e.target.value)}
          placeholder="Enter Aadhar number"
          autoComplete="off"
        />
      </FormControl>
    </div>
  );
};

export default DependentDetails;
