import AdminLogin from '../pages/admin/AdminLogin'
import ValidateUsers from '../pages/admin/ValidateUsers'
import Form from '../pages/form'
import Login from '../pages/Login'
import Otp from '../pages/Otp'
import Preview from '../pages/preview'
import Register from '../pages/Register'
import Success from '../pages/Success'

export interface IRoute {
	path: string
	name?: string
	Component: () => JSX.Element
}
const routes: Array<IRoute> = [
	{
		path: '/register',
		Component: Register,
	},
	{
		path: '/login',
		Component: Login,
	},
	{
		path: '/otp',
		Component: Otp,
	},
	{
		path: '/form',
		Component: Form,
	},
	{
		path: '/admin/validate-members',
		Component: ValidateUsers,
	},
	{
		path: '/admin/login',
		Component: AdminLogin,
	},
	{
		path: '/success',
		Component: Success,
	},
	{
		path: '/preview',
		Component: Preview,
	},
]

export default routes
