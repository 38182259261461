import DeleteIcon from '@mui/icons-material/Delete'
import {CircularProgress, IconButton} from '@mui/material'
import {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import http from '../utils/http'

const CustomUpload = ({name, onChange, data}: any) => {
	const [fileName, setFileName] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const formRef: any = useRef()

	const handleChange = (e: any) => {
		if (e.target.files[0]) {
			const formData = new FormData()
			formData.append('file', e.target.files[0])
			setLoading(true)
			http
				.post('/upload', formData)
				.then((res) => {
					setFileName(e.target.files[0].name)
					onChange(res.data, e)
					setLoading(false)
				})
				.catch((err) => {
					setLoading(false)
					toast.error('Server Error While Uploading File')
				})
		}
	}

	useEffect(() => {
		setFileName(data?.key)
	}, [data])

	return (
		<form ref={formRef} className='mt-3'>
			<div className='md:flex items-center gap-4'>
				<div className=''>
					<label
						htmlFor={name}
						className='px-4 text-sm md:text-base  py-1 border-2 cursor-pointer border-primary rounded-md text-primary font-semibold  hover:bg-primary hover:bg-opacity-10 '
					>
						<span>Upload</span>
					</label>
				</div>
				<div className='pt-4 md:pt-0'>
					<span className='text-primary font-medium text-sm md:text-base'>
						{loading ? (
							<CircularProgress size={25} />
						) : (
							<>
								{fileName && (
									<p>
										{fileName}
										<IconButton
											disableRipple
											onClick={() => {
												setFileName(null)
												formRef?.current.reset()
											}}
										>
											<DeleteIcon fontSize='small' color='primary' />
										</IconButton>
									</p>
								)}
							</>
						)}
					</span>
				</div>
			</div>
			<input
				onChange={handleChange}
				required
				type='file'
				accept='image/*'
				id={name}
				name={name}
				className='hidden'
			/>
		</form>
	)
}

export default CustomUpload
