import { TextField } from '@mui/material'

const MyTextField = ({ ...props }) => {
	return (
		<TextField
			variant='outlined'
			{...props}
			fullWidth
			size='small'
			className=''
		/>
	)
}

export default MyTextField
