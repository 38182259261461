import {createContext, useContext, useState} from 'react'

// const DataContext: Context<any> = createContext(null)
const DataContext = createContext<any>(null)

const DataProvider: any = ({children}: any) => {
	const [bocwMember, setBocwMember] = useState<any>(null)
	const [loginMobile, setLoginMobile] = useState<any>(null)

	return (
		<DataContext.Provider
			value={{bocwMember, setBocwMember, loginMobile, setLoginMobile}}
		>
			{children}
		</DataContext.Provider>
	)
}

export default DataProvider

export const useDataContext = () => {
	const {bocwMember, setBocwMember, loginMobile, setLoginMobile} =
		useContext(DataContext)
	return {
		bocwMember,
		setBocwMember,
		loginMobile,
		setLoginMobile,
	}
}
