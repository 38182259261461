import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {CopyToClipboard} from '../components'
import {useDataContext} from '../context/DataContext'

const Success = () => {
	const {setBocwMember} = useDataContext()
	const location = useLocation()
	let searchParams = new URLSearchParams(location.search)
	const id = searchParams.get('formId')

	useEffect(() => {
		setBocwMember(null)
	}, [])

	return (
		<div className='flex justify-center items-center' style={{height: '60vh'}}>
			<div className='w-2/3 text-center'>
				<div className=''>
					<img
						src='/images/tick.png'
						alt='checkIcon'
						className='block mx-auto mb-6'
					/>
				</div>
				<p className='font-bold text-lg'>Registration Application Submitted</p>
				<div className='flex mt-8'>
					<div className='mx-auto'>
						<CopyToClipboard id={id} />
					</div>
					<div className=''></div>
				</div>
				<p className='mt-12'>
					Thank you for applying, Our team will provide you with the credentials
					to login to view the online courses.
				</p>
			</div>
		</div>
	)
}

export default Success
