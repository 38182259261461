import {Alert, TextField} from '@mui/material'
import {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Button} from '../components'
import {useDataContext} from '../context/DataContext'
import http from '../utils/http'

const Register = () => {
	const [memberID, setMemberID] = useState<string>('')
	const [error, setError] = useState(null)
	const {setBocwMember} = useDataContext()
	const history = useHistory()

	const handleSubmit = (e: any) => {
		e.preventDefault()
		postData()
	}

	const postData = async () => {
		try {
			const res = await http.get(`/bocw-user/registration-number/${memberID}`)
			setBocwMember(res.data)
			res.data && history.push(`/form/${res.data.registrationNumber}`)
		} catch (err: any) {
			setError(err.response.data.message)
			setMemberID('')
		}
	}

	return (
		<div className='flex  items-center h-full'>
			<form className='m-12 gap-4 w-full' onSubmit={handleSubmit}>
				{error && (
					<Alert severity='error' className='capitalize mb-5'>
						{error}
					</Alert>
				)}
				<label className='font-bold'>BCOW Member ID : </label>
				<div className='my-4'>
					<TextField
						size='medium'
						required
						variant='outlined'
						fullWidth
						label='Enter your BOCW Member ID'
						value={memberID}
						onChange={({target}) => setMemberID(target.value)}
					/>
				</div>
				<Button label='Submit' size='large' className='mt-4' type='submit' />
				<div className='mt-8'>
					<p>
						Or{' '}
						<span className='font-semibold text-primary hover:underline'>
							<Link to='/login'> Login </Link>
						</span>{' '}
						using Mobile Number
					</p>
				</div>
			</form>
		</div>
	)
}

export default Register
