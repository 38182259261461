const FormControl = ({label, children, required}: IFormConrol) => {
	return (
		<div className='md:flex my-3 w-full'>
			<label htmlFor={label} className='text-sm md:w-1/3 mt-2.5'>
				{label} <span className='text-red-500'>{required && '*'}</span>
			</label>
			<div className='md:w-2/3 mt-0.5'>{children}</div>
		</div>
	)
}

export default FormControl

interface IFormConrol {
	label: string
	children: any
	required?: boolean
}
