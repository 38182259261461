import { Alert, CircularProgress } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../components";
import { useDataContext } from "../context/DataContext";
import http from "../utils/http";
import { formState, formFieldData } from "./form";
import { enclosures } from "./form/Enclosures";
import "../styles/preview-page.css";
import { KeyboardBackspace } from "@mui/icons-material";

export default function Preview() {
  const history = useHistory();
  const [posting, setPosting] = useState<boolean>(false);
  const state = formState.useState((s) => s);
  const data = formFieldData.useState((s) => s);
  const files = enclosures.useState((s) => s);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (!bocwMember) {
      window.location.href = "/";
    }
  }, []);

  const { bocwMember } = useDataContext();
  console.log(state.education);

  const postData = async () => {
    const props: AxiosRequestConfig = {
      url: "/registration-form",
      method: "POST",
    };

    try {
      setPosting(true);
      const res: any = await http({
        ...props,
        data: {
          ...state,
          higherEducation: `${state.education.name}`,
          courseId: state.course.id,
          reqcode: bocwMember?.reqcode,
          workerName: bocwMember?.worker_name,
          bocRegNo: bocwMember?.regno,
          district: data?.districts?.find(
            (item) => item?.id == bocwMember?.distcode
          )?.name,
        },
      });

      setPosting(false);
      res && history.push(`/success?formId=${res.data?.formId}`);
    } catch (err: any) {
      setAlertMessage(err?.response?.data?.message);
      setPosting(false);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    postData();
  };

  return (
    <div
      style={{
        padding: "2rem",
      }}
    >
      <div className="mt-5">
        <Link className="hover:underline text-primary" to="/form">
          <div className="flex items-center gap-3">
            <KeyboardBackspace />
            <p>Go Back</p>
          </div>
        </Link>
      </div>
      <header>
        <div className="title">
          <h1>
            Telangana Building And Other Construction Workers Welfare Board
          </h1>
          <h2>
            SETWIN Application form for Online Skill Upgradation Training
            Programme
            <br />
            for Registered BOC Workers and their Dependents
          </h2>
        </div>
        <div className="passport-photo">
          <img
            height="100%"
            width="100%"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            src={files.applicantPhoto?.Location}
            alt="passport size photo"
          />
        </div>
      </header>
      <main className="page">
        <div className="field-section">
          <section className="details">
            <ol>
              <div className="form-control">
                Batch Number
                <p>{state?.batchNumber}</p>
              </div>
              <div className="form-control">
                <li>Name of the Worker</li>
                <p>{state?.workerName}</p>
              </div>
              <div className="form-control">
                <li>BOC CARD Number</li>
                <p>{bocwMember?.regno}</p>
              </div>
              <div className="form-control">
                <li>Name of Worker/Dependent</li>
                <p>{state?.name}</p>
              </div>
              <div className="form-control">
                <li>Dependent Mother Name</li>
                <p>{state?.motherName}</p>
              </div>
              <div className="form-control">
                <li>Education Qualification</li>
                <p>{`${state?.education?.name}`}</p>
              </div>
              <div className="form-control">
                Course Selected
                <p>{state?.course?.name}</p>
              </div>
              <div className="form-control">
                <li>Mobile Number of Dependent</li>
                <p>{state?.mobile}</p>
              </div>
              <div className="form-control">
                <li>District</li>
                <p>{bocwMember?.distname}</p>
              </div>
              <div className="form-control">
                <li>Trainee Aadhar Number</li>
                <p>{state?.aadharNumber}</p>
              </div>
            </ol>
          </section>
        </div>
        <div className="aadhar-section">
          <img
            src={files.aadharFile?.Location}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </main>
      <footer>
        <div className="signs">
          <div className="candidate-sign">
            <img
              src={files.sign?.Location}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt="Signature of candidate"
            />
            <p style={{ textAlign: "center", paddingTop: "10px" }}>
              Signature of the Candidate
            </p>
          </div>
          <div className="alo-sign">
            <div
              style={{
                height: "100px",
                width: "200px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            ></div>
            <p style={{ textAlign: "center", paddingTop: "10px" }}>
              Signature of ALO
            </p>
          </div>
        </div>
      </footer>
      <form onSubmit={handleSubmit}>
        <div className="my-3">
          {alertMessage && <Alert severity="error">{alertMessage}</Alert>}
        </div>
        <div className="flex justify-center items-center">
          <Button
            disabled={posting}
            endIcon={posting ? <CircularProgress size={20} /> : null}
            label="Submit"
            type="submit"
            className="w-1/2 mx-auto mt-8"
          />
        </div>
      </form>
    </div>
  );
}
